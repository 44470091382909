import { AddressSuggestions } from 'react-dadata'
import {
  AutoComplete,
  Button,
  Col,
  Flex,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Typography,
} from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { CalendarOutlined, InfoCircleFilled } from '@ant-design/icons'
import { TimeRangePicker } from '@components/ui/time-range-picker/time-range-picker.component'

import { QuestionTooltip } from '../../../ui/question-tooltip/question-tooltip.component'
import { CustomFieldsApi } from '../../../../api/types/custom-fields-api.types'
import { CustomCard } from '../../../custom-card/custom-card.component'
import { StudiosFormTypes } from '../studios-form-types'
import { ImageUploadGallery } from '../../../image-upload-gallery/image-upload-gallery'
import { CustomFieldsSettingsGenInput } from '../../../custom-fields-settings/custom-fields-settings.component'
import { useStudiosFormGeneralInfo } from './studios-form-general-info.hook'
import { StudiosFormLocation } from '../studios-form-location/studios-form-location.component'
import { StudiosFormWorkTimeModal } from '../studios-form-work-time-modal/studios-form-work-time-modal.component'
import { StudiosFormAmenities } from '../studios-form-amenities/studios-form-amenities.component'
import './studios-form-general-info.styles.less'

interface Props {
  form: FormInstance<StudiosFormTypes>
  isLoading?: boolean
  franchisesOptions?: DefaultOptionType[]
  customFields: CustomFieldsApi.CustomField[]
  onChangeCustomFields: any
}

export const StudiosFormGeneralInfo: React.FC<Props> = props => {
  const { form, franchisesOptions, customFields, onChangeCustomFields } = props

  const {
    countryRef,
    addressRef,
    timeToValidationRules,
    photos,
    unifiedTime,
    country,
    isModalOpen,
    timeDisabled,
    isTagVisible,
    toggleOnlineBusiness,
    toggleHideStudio,
    setIsTagVisible,
    setCountry,
    onChangeTimeHandler,
    onChangePhotos,
    onOpenModal,
    onModalCancel,
    onModalConfirm,
  } = useStudiosFormGeneralInfo({ form })

  return (
    <Flex gap="middle" vertical>
      <CustomCard>
        <Flex gap="large" vertical>
          <Typography.Title level={4} className={'studios-form__title'}>
            Контакты и название
          </Typography.Title>
          <Row gutter={24}>
            <Col flex="8">
              <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Введите название студии' }]}>
                <Input size="large" placeholder="Уникальное название" />
              </Form.Item>
            </Col>
            <Col flex="16">
              <Row gutter={24}>
                <Col flex="1 1 0">
                  <Form.Item
                    label="Юридическое лицо"
                    name="orgId"
                    rules={[{ required: true, message: 'Выберите компанию' }]}
                  >
                    <Select
                      size="large"
                      options={franchisesOptions}
                      placeholder="Выберите"
                      style={{ width: '100%', maxWidth: '360px' }}
                    />
                  </Form.Item>
                </Col>
                <Col flex="1 1 0">
                  <Form.Item
                    name={['phoneNumber', 'number']}
                    label="Номер телефона"
                    // rules={[{ required: true, message: 'Ввведите номер' }]}
                    className={'studios-form-general-info__phone'}
                  >
                    <Input
                      size="large"
                      disabled
                      addonBefore={
                        <Form.Item name={['phoneNumber', 'code']} initialValue={7}>
                          <Select disabled options={[{ label: '+7', value: 7 }]} />
                        </Form.Item>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Flex gap="middle" vertical>
            <Space size="large" align="center">
              <Typography.Title level={5} className={'studios-form__title'}>
                Адрес и часы работы
              </Typography.Title>
              <Space align="center" size="small">
                <Form.Item name="onlineBusiness">
                  <Switch size="small" />
                </Form.Item>
                <Typography.Text className="studios-form__toggled-title" onClick={toggleOnlineBusiness}>
                  Онлайн бизнес
                </Typography.Text>
                <QuestionTooltip title="" />
              </Space>
            </Space>
            <Row gutter={24}>
              <Col flex="8">
                <Form.Item
                  label="Страна"
                  name="country"
                  rules={[{ required: true, message: 'Выберите страну' }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <AddressSuggestions
                    autoload
                    filterFromBound="country"
                    filterToBound="country"
                    filterLocations={[{ country_iso_code: '*' }]}
                    token="a01a6fc75c1577ee300aac65cf8bb1e649f15afb"
                    ref={countryRef}
                    customInput={props => {
                      const customChange = (value: string) => {
                        form.validateFields(['country'])
                        props.onChange({ target: value })
                        countryRef.current?.setInputValue(value)
                        form.setFieldValue('country', value)
                      }
                      const options = countryRef.current?.state.suggestions.map(s => ({
                        label: s.value,
                        value: s.value,
                      }))
                      return (
                        <AutoComplete
                          size="large"
                          className="studios-form-general-info__auto-complete"
                          options={options}
                          value={form.getFieldValue('country')}
                          placeholder="Выберите страну"
                          onChange={customChange}
                          onBlur={() => setCountry(form.getFieldValue('country'))}
                        />
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col flex="16">
                <Form.Item label="Адрес студии" name="address" rules={[{ required: true, message: 'Введите адрес' }]}>
                  <AddressSuggestions
                    autoload
                    token="a01a6fc75c1577ee300aac65cf8bb1e649f15afb"
                    ref={addressRef}
                    filterLocations={[{ country }]}
                    customInput={props => {
                      const customChange = (value: string) => {
                        form.validateFields(['address'])
                        props.onChange({ target: value })
                        addressRef.current?.setInputValue(value)
                        form.setFieldValue('address', value)
                      }
                      const options = addressRef.current?.state.suggestions.map(s => ({
                        label: s.value,
                        value: s.value,
                        city: s.data.city,
                        country: s.data.country,
                        timezone: s.data.timezone,
                      }))
                      return (
                        <AutoComplete
                          size="large"
                          className="studios-form-general-info__auto-complete"
                          options={options}
                          value={form.getFieldValue('address')}
                          placeholder="Введите адрес"
                          onChange={customChange}
                          onSelect={(_, option) => {
                            const offset = Number(option.timezone?.slice(3))
                            form.setFieldValue('city', option.city)
                            form.setFieldValue('country', option.country)
                            form.setFieldValue('offset', offset)
                          }}
                        />
                      )
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Flex gap="small">
              <Form.Item
                label="Часы работы"
                name="unifiedTime"
                rules={!timeDisabled ? timeToValidationRules : undefined}
              >
                <TimeRangePicker times={unifiedTime} disabled={timeDisabled} onChange={onChangeTimeHandler} />
              </Form.Item>
              <Form.Item noStyle name="workTime"></Form.Item>
              <Button icon={<CalendarOutlined />} style={{ marginTop: '30px' }} onClick={onOpenModal}>
                Настроить каждый день
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex gap="large" vertical>
          <Flex gap="middle" vertical>
            <Typography.Title level={4} className={'studios-form__title'}>
              Описание, как пройти и галерея
            </Typography.Title>
            <Space align="center" size="small">
              <Form.Item name="hideStudio">
                <Switch size="small" />
              </Form.Item>
              <Typography.Text className="studios-form__toggled-title" onClick={toggleHideStudio}>
                Cкрывать студию
              </Typography.Text>
            </Space>
          </Flex>
          <Row>
            <Col span={12}>
              <Form.Item label="О студии" name="description">
                <Input.TextArea size="large" rows={2} />
              </Form.Item>
            </Col>
          </Row>
          <Flex gap="middle" vertical>
            <Typography.Title level={5} className={'studios-form__title'}>
              Как найти студию?
            </Typography.Title>
            <StudiosFormLocation form={form} />
          </Flex>
          <Flex gap="middle" vertical>
            <Typography.Title level={5} className={'studios-form__title'}>
              Галерея
            </Typography.Title>
            <Form.Item name="photos">
              <ImageUploadGallery fileList={photos} onChange={onChangePhotos} />
            </Form.Item>
          </Flex>
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex gap="large" vertical>
          <Typography.Title level={4} className={'studios-form__title'}>
            Сервис студии
          </Typography.Title>
          <StudiosFormAmenities form={form} />
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex gap="large" vertical>
          <Typography.Title level={4} className={'studios-form__title'}>
            Кастомные данные
          </Typography.Title>
          {isTagVisible && (
            <Row>
              <Col>
                <Tag
                  className="studios-form__tag"
                  icon={<InfoCircleFilled style={{ fontSize: '16px' }} />}
                  color="blue"
                  closable
                  onClose={() => setIsTagVisible(false)}
                >
                  <p>Хотите больше параметров для вашей студии? Настраивайте здесь</p>
                </Tag>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={12}>
              <CustomFieldsSettingsGenInput
                customFields={customFields}
                handleInputChange={onChangeCustomFields}
                hideTitle
                size="large"
              />
            </Col>
          </Row>
        </Flex>
      </CustomCard>
      <StudiosFormWorkTimeModal
        values={form.getFieldValue('workTime')}
        offset={form.getFieldValue('offset')}
        open={isModalOpen}
        onOk={onModalConfirm}
        onCancel={onModalCancel}
      />
    </Flex>
  )
}
