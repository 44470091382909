import { Form, FormInstance, UploadFile } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { AddressSuggestions } from 'react-dadata'

import { WorkTime } from '../../../../api/types/api.types'
import { isDef, isDefAndNotEmpty, Nullable } from '../../../../types/lang.types'
import { genDays, isContainArrayDaysWithSameTime } from '../../../../utils/days.utils'
import { StudiosFormTypes } from '../studios-form-types'
import { genStudiosFormTimeToValidationRules } from './studios-form-general-info.utils'

interface Props {
  form: FormInstance<StudiosFormTypes>
}

export function useStudiosFormGeneralInfo(props: Props) {
  const { form } = props

  const [photos, setPhotos] = useState<UploadFile[]>([])
  const [country, setCountry] = useState()

  const [isTagVisible, setIsTagVisible] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [timeDisabled, setTimeDisabled] = useState(false)

  const timeToValidationRules = useMemo(genStudiosFormTimeToValidationRules, [])

  const formPhotos = Form.useWatch('photos', form)
  const unifiedTime = Form.useWatch('unifiedTime', form)
  const workTime = Form.useWatch('workTime', form)
  const onlineBusiness = Form.useWatch('onlineBusiness', form)
  const hideStudio = Form.useWatch('hideStudio', form)

  const addressRef = useRef<AddressSuggestions>(null)
  const countryRef = useRef<AddressSuggestions>(null)

  const days = useMemo(genDays, [])

  const toggleOnlineBusiness = () => {
    form.setFieldValue('onlineBusiness', !onlineBusiness)
  }

  const toggleHideStudio = () => {
    form.setFieldValue('hideStudio', !hideStudio)
  }

  const onChangeTimeHandler = (value: { start: string; end: string }): void => {
    const offset = form.getFieldValue('offset')
    form.setFieldValue('unifiedTime', value)
    const weekdaysArray: any = {}

    days.forEach(weekday => {
      weekdaysArray[weekday] = {
        from: dayjs(value.start, 'HH:mm').utcOffset(offset, true).format('HH:mmZ'),
        to: dayjs(value.end, 'HH:mm').utcOffset(offset, true).format('HH:mmZ'),
      }
    })

    form.setFieldValue('workTime', weekdaysArray)
  }

  const onChangePhotos = (photos: UploadFile[]) => {
    form.setFieldValue('photos', photos)
    setPhotos(photos)
  }

  const onOpenModal = () => {
    setIsModalOpen(true)
  }

  const onModalCancel = () => {
    setIsModalOpen(false)
  }

  const onModalConfirm = (values: Nullable<WorkTime>, unifiedTime: boolean) => {
    if (isDef(values)) {
      form.setFieldValue('workTime', values)
      setTimeDisabled(!unifiedTime)
      if (unifiedTime) {
        form.setFieldValue('unifiedTime', {
          start: dayjs(values.MONDAY.from, 'HH:mm').format('HH:mm'),
          end: dayjs(values.MONDAY.to, 'HH:mm').format('HH:mm'),
        })
      }
    }
    onModalCancel()
  }

  useEffect(() => {
    if (!isDefAndNotEmpty(photos)) {
      setPhotos(form.getFieldValue('photos'))
    }
  }, [photos, form, formPhotos])

  useEffect(() => {
    const workTime = form.getFieldValue('workTime')
    if (workTime && Object.keys(workTime).length !== 0) {
      setTimeDisabled(!isContainArrayDaysWithSameTime(Object.values(workTime)))
    }
  }, [form, workTime])

  return {
    countryRef,
    addressRef,
    timeToValidationRules,
    photos,
    unifiedTime,
    country,
    timeDisabled,
    isModalOpen,
    isTagVisible,
    toggleOnlineBusiness,
    toggleHideStudio,
    setIsTagVisible,
    setCountry,
    onChangeTimeHandler,
    onChangePhotos,
    onOpenModal,
    onModalCancel,
    onModalConfirm,
  }
}
