import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  genScheduleManagementPagePeekHistoryIsLoading,
  genScheduleManagementPagePeekHistoryScheduleHistory,
  genScheduleManagementPagePeekHistoryTotalElements,
} from '../../../store/pages/schedule-management-page/schedule-management-page-peek-history/schedule-management-page-peek-history.selectors'
import { scheduleManagementPagePeekHistoryActions } from '../../../store/pages/schedule-management-page/schedule-management-page-peek-history/schedule-management-page-peek-history.slice'
import { genListPaginationConfig } from '../../../utils/pagination.utils'
import { ExerciseTypeFormat } from '../../../api/types/api.types'

const SCHEDULE_MANAGEMENT_PAGE_PEEK_HISTORY_INITIAL_PAGE: number = 0
const SCHEDULE_MANAGEMENT_PAGE_PEEK_HISTORY_INITIAL_SIZE: number = 25

export function useScheduleManagementPagePeekHistory(id: string, format: ExerciseTypeFormat) {
  const dispatch = useDispatch()

  const scheduleHistory = useSelector(genScheduleManagementPagePeekHistoryScheduleHistory)
  const isLoading = useSelector(genScheduleManagementPagePeekHistoryIsLoading)
  const totalElements = useSelector(genScheduleManagementPagePeekHistoryTotalElements)

  const [page, setPage] = useState(SCHEDULE_MANAGEMENT_PAGE_PEEK_HISTORY_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(SCHEDULE_MANAGEMENT_PAGE_PEEK_HISTORY_INITIAL_SIZE)

  const pagination = useMemo(
    () => genListPaginationConfig(page, pageSize, totalElements),
    [page, pageSize, totalElements]
  )

  const onChangePageHandler = useCallback((page: number, pageSize: number): void => {
    setPage(page)
    setPageSize(pageSize)
  }, [])

  const onChangePageSizeHandler = useCallback((pageSize: number): void => {
    setPageSize(pageSize)
  }, [])

  useEffect(() => {
    dispatch(
      scheduleManagementPagePeekHistoryActions.fetchAudit({
        id,
        format,
        page,
        size: pageSize,
      })
    )
  }, [dispatch, id, format, page, pageSize])

  useEffect(() => {
    return () => {
      dispatch(scheduleManagementPagePeekHistoryActions.reset())
    }
  }, [dispatch])

  return {
    scheduleHistory,
    isLoading,
    pagination,
    onChangePageHandler,
    onChangePageSizeHandler,
  }
}
