import { put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { ExerciseTypeFormat } from '../../../../api/types/api.types'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { callApi } from '../../../../utils/sagas.utils'
import { scheduleManagementPagePeekHistoryActions } from './schedule-management-page-peek-history.slice'

function* fetchAudit(action: ReturnType<typeof scheduleManagementPagePeekHistoryActions.fetchAudit>) {
  try {
    const { page, size, id, format } = action.payload

    const params = genPaginationParamsDTO(page, size)
    const entity = format === ExerciseTypeFormat.PERSONAL ? 'multibooking' : 'timetable'

    const response: Awaited<ReturnType<typeof api.auditService.fetchById>> = yield callApi(
      api.auditService.fetchById,
      entity,
      id,
      { ...params, sort: 'time,desc' }
    )
    yield put(scheduleManagementPagePeekHistoryActions.fetchAuditSuccess(response.data))
  } catch (e) {
    yield put(scheduleManagementPagePeekHistoryActions.fetchAuditError(new Error()))
  }
}

export function* scheduleManagementPagePeekHistorySagas() {
  yield takeLatest(scheduleManagementPagePeekHistoryActions.fetchAudit.type, fetchAudit)
}
