import { ExercisesGroupEditForm } from '../../../components/exercises-group/exercises-group-edit-form/exercises-group-edit-form.component'
import { useScheduleGroupPageEdit } from './schedule-group-page-edit.hook'

export function ScheduleGroupPageEdit() {
  const {
    schedule,
    isCompleted,
    studioOffset,
    form,
    isLoading,
    trainersOptions,
    studiosOptions,
    studiosRoomsOptions,
    directionsOptions,
    exercisesTypes,
    onSaveHandler,
    onCancelHandler,
    masterServicesOptions,
    subServicesOptions,
    onChangeMasterServiceHandler,
    onChangeSubServicesHandler,
  } = useScheduleGroupPageEdit()

  return (
    <ExercisesGroupEditForm
      onChangeMasterServiceHandler={onChangeMasterServiceHandler}
      onChangeSubServicesHandler={onChangeSubServicesHandler}
      exercisesTypesOptions={exercisesTypes}
      masterServicesOptions={masterServicesOptions}
      subServicesOptions={subServicesOptions}
      schedule={schedule}
      isCompleted={isCompleted}
      form={form}
      loading={isLoading}
      studioOffset={studioOffset}
      trainersOptions={trainersOptions}
      studiosOptions={studiosOptions}
      studiosRoomsOptions={studiosRoomsOptions}
      directionsOptions={directionsOptions}
      onSave={onSaveHandler}
      onCancel={onCancelHandler}
    />
  )
}
