import { PaginationConfig } from 'antd/lib/pagination'

import { AuditApi } from '../../api/types/audit.types'
import { AuditAction } from '../../types/audit.types'
import { Nullable } from '../../types/lang.types'

export interface HistoryListProps {
  title?: string
  type: HistoryListType
  history: HistoryItem[]
  pagination?: PaginationConfig
  onChangePage: (page: number, pageSize: number) => void
  onChangePageSize: (pageSize: number) => void
}

export interface HistoryItem {
  id: string
  subject: AuditApi.Subject
  parameterObject: Nullable<AuditApi.ParameterObject>
  action: AuditAction
  changes: AuditApi.Changes[]
  date: string
  time: string
}

export enum HistoryListType {
  EXERCISE = 'EXERCISE',
  TIMETABLE = 'TIMETABLE',
  MULTIBOOKING = 'MULTIBOOKING',
  MASTER_SERVICE = 'MASTER_SERVICE',
  TRANSACTION = 'TRANSACTION',
  CLIENT = 'CLIENT',
  EMPLOYEE = 'EMPLOYEE',
  STUDIO = 'STUDIO',
  ONE_TIME_PRODUCT = 'ONE_TIME_PRODUCT',
  SUBSCRIPTION_PRODUCT = 'SUBSCRIPTION_PRODUCT',
  GOODS = 'GOODS',
  SCHEDULE = 'SCHEDULE',
}
