import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { AuditApi } from '../../../../api/types/audit.types'
import { Nullable } from '../../../../types/lang.types'
import { ScheduleManagementPagePeekHistoryFetchAuditPayload } from './schedule-management-page-peek-history.types'

export interface ScheduleManagementPagePeekHistoryState {
  scheduleAudit: Nullable<Pagination<AuditApi.Audit>>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleManagementPagePeekHistoryState = {
  scheduleAudit: null,
  isLoading: false,
  error: null,
}

export const { actions: scheduleManagementPagePeekHistoryActions, reducer: scheduleManagementPagePeekHistoryReducer } =
  createSlice({
    name: '@@schedule-management-page-peek-history',
    initialState,
    reducers: {
      fetchAudit: (
        state: Draft<ScheduleManagementPagePeekHistoryState>,
        _: PayloadAction<ScheduleManagementPagePeekHistoryFetchAuditPayload>
      ): void => {
        state.isLoading = true
      },
      fetchAuditSuccess: (
        state: Draft<ScheduleManagementPagePeekHistoryState>,
        action: PayloadAction<Pagination<AuditApi.Audit>>
      ): void => {
        state.scheduleAudit = action.payload
        state.isLoading = false
      },
      fetchAuditError: (state: Draft<ScheduleManagementPagePeekHistoryState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  })
