import * as React from 'react'
import { Button, Modal, Tooltip } from 'antd'
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  QrcodeOutlined,
} from '@ant-design/icons'
import { getKkmCommands } from '@utils/api/sync-kkm-with-backend/utils/get-kkm-commands.utils'
import { syncKkmWithBackend } from '@utils/api/sync-kkm-with-backend/sync-kkm-with-backend.utils'
import { UPDATE_CLIENT_ACTIVE_RECORDS_BROADCAST } from '@constants/broadcast'

import { ClientsBookingsTableActiveActionsEvents } from './clients-bookings-table-active-actions.types'
import { formatRublesPenniesCurrency } from '../../../../../format/number.format'
import { PaymentType } from '../../../../../types/payment.types'
import './clients-bookings-table-active-actions.styles.less'

interface Props extends ClientsBookingsTableActiveActionsEvents {
  bookingId: string
  exerciseId: string
  studioId: string
  paymentType: PaymentType
}

export const ClientsBookingsTableActiveActions: React.FC<Props> = props => {
  const { bookingId, exerciseId, studioId, paymentType } = props
  const { onBarcode, onBooking, onHistory } = props

  const onBarcodeHandler = React.useCallback((): void => {
    onBarcode(bookingId, exerciseId)
  }, [bookingId, exerciseId, onBarcode])

  const onCancelHandler = async () => {
    const kkmCommand = await getKkmCommands(api => api.exercisesService.fetchCancelBooking(exerciseId, bookingId))
    const refundSum = formatRublesPenniesCurrency(kkmCommand?.response?.cancellationOptions?.money?.refundSum ?? 0)

    const onCancelModal = async () => {
      await syncKkmWithBackend(
        async () => ({
          data: {
            ...kkmCommand?.response,
            kkmCommandData: kkmCommand?.response?.cancellationOptions?.money?.kkmCommandData,
          },
        }),
        async (api, kkmCommandResponses) => {
          const response = await api.exercisesService.cancelBooking(exerciseId, bookingId, {
            kkmCommandResponses,
          })

          return response
        },
        'Запись успешно отменена'
      )

      const bc = new BroadcastChannel(UPDATE_CLIENT_ACTIVE_RECORDS_BROADCAST)
      bc.postMessage(
        JSON.stringify({
          command: 'update',
        })
      )
    }

    Modal.confirm({
      title: 'Отмена записи клиента',
      icon: <ExclamationCircleOutlined />,
      content: `Вы уверены, что хотите отменить запись клиента на сумму ${refundSum}?`,
      cancelText: 'Нет',
      okText: 'Да',
      onOk: async () => await onCancelModal(),
    })
  }

  const onBookingHandler = React.useCallback((): void => {
    onBooking(studioId, exerciseId)
  }, [exerciseId, onBooking, studioId])

  const isBarcodeShown = [PaymentType.ONE_TIME, PaymentType.SUBSCRIPTION, PaymentType.RESERVED].includes(paymentType)

  const onHistoryHandler = React.useCallback(() => onHistory(exerciseId), [exerciseId, onHistory])

  return (
    <div className="clients-bookings-table-active-actions">
      <Tooltip title="Посмотреть историю изменений">
        <Button icon={<HistoryOutlined />} size="middle" onClick={onHistoryHandler} />
      </Tooltip>
      {isBarcodeShown && (
        <Tooltip title="Показать чек">
          <Button icon={<QrcodeOutlined />} size="middle" onClick={onBarcodeHandler} />
        </Tooltip>
      )}
      <Tooltip title="Перейти к группе">
        <Button icon={<InfoCircleOutlined />} size="middle" onClick={onBookingHandler} />
      </Tooltip>
      <Tooltip title="Отменить запись">
        <Button icon={<DeleteOutlined />} size="middle" onClick={onCancelHandler} danger />
      </Tooltip>
    </div>
  )
}
