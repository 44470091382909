import { FC } from 'react'

import { HistoryList } from '../../../components/history-list/history-list.component'
import { HistoryListType } from '../../../components/history-list/history-list.types'
import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { SidePeek } from '../../../components/side-peek/side-peek.component'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { useEmployeesSchedulePeekHistory } from './employees-schedule-peek-history.hook'
import { EmployeesSchedulePeekHistoryProps } from './employees-schedule-peek-history.types'

export const EmployeesSchedulePeekHistory: FC<EmployeesSchedulePeekHistoryProps> = props => {
  const { id, name, position } = props
  const { scheduleHistory, isLoading, pagination, onChangePageHandler, onChangePageSizeHandler } =
    useEmployeesSchedulePeekHistory(id)

  return (
    <SidePeek>
      {isLoading ? (
        <PageLoader />
      ) : isDefAndNotEmpty(scheduleHistory) ? (
        <HistoryList
          title={`История действий с графиком ${name ?? position}`}
          type={HistoryListType.SCHEDULE}
          history={scheduleHistory}
          pagination={pagination}
          onChangePage={onChangePageHandler}
          onChangePageSize={onChangePageSizeHandler}
        />
      ) : (
        <PageEmpty description={'История не найдена'} />
      )}
    </SidePeek>
  )
}
