import { LineChart } from './line-chart.components'
import { FunnelChart } from './funnel-chart.component'
import styles from './analytics-page.module.scss'
import { StackedBarChart } from './stacked-bar-chart/stacked-bar-chart.component'
import { ProgressAnalytic } from './progress-analytic/progress-analytic.component'
import { TableAnalytic } from './table-analytic/table-analytic.component'

export const AnalyticsPage = () => {
  return (
    <div className={styles.container}>
      {/* <FunnelChart /> */}
      <div className={styles.lines}>
        <LineChart />
        {/* <LineChart /> */}
      </div>
      {/* <div className={styles.progresses}>
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
      </div> */}
      {/* <TableAnalytic /> */}
      {/* <StackedBarChart /> */}
    </div>
  )
}
