// @ts-nocheck
import { DefaultOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'
import { UploadFile } from 'antd/es/upload/interface'

import { StudiosApi } from '../api/types/studios-api.types'
import { isDef, isDefAndNotEmpty, NString, Nullable } from '../types/lang.types'
import { StudiosTableRow } from '../components/studios/studios-table/studios-table.types'
import { StudiosFormTypes } from '../components/studios/studios-form/studios-form-types'
import { Days, WorkTime } from '../api/types/api.types'
import { AppLayoutSideBarStudio } from '../components/layouts/app-layout/app-layout-sidebar/app-layout-sidebar.types'
import { validateStrEnumValue } from '../utils/enum.utils'
import { Range } from '../api/types/api.types'
import { isContainArrayDaysWithSameTime } from '../utils/days.utils'
import { StudiosRoomsApi } from '../api/types/studios-rooms-api.types'

export function mapStudiosToAppLayoutTopBarStudios(
  studios: Nullable<StudiosApi.Studio[]>
): Nullable<AppLayoutSideBarStudio[]> {
  if (isDefAndNotEmpty(studios)) {
    return studios.reduce<AppLayoutSideBarStudio[]>((acc, studio) => {
      if (isDef(studio.offset)) {
        acc.push({
          id: studio.id,
          title: studio.name,
          offset: studio.offset,
          organization: studio?.organization?.name,
        })
      }

      return acc
    }, [])
  }

  return null
}

export function mapStudiosToOptions(studios: Nullable<StudiosApi.Studio[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(studios)) {
    return studios.map(
      (studios: StudiosApi.Studio): DefaultOptionType => ({
        value: studios.id,
        label: studios.name,
      })
    )
  }
}

export function mapStudiosToRoomsOptions(studios: Nullable<StudiosApi.Studio[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(studios)) {
    return studios.reduce<DefaultOptionType[]>((acc, studio) => {
      if (isDef(studio.rooms)) {
        studio.rooms.forEach(room => {
          acc.push({
            value: room.id,
            label: room.name,
            studioId: room.studioId,
          })
        })
      }

      return acc
    }, [])
  }

  return undefined
}

export function mapStudiosToStudiosTableRowList(studios: Nullable<StudiosApi.Studio[]>): Nullable<StudiosTableRow[]> {
  if (isDefAndNotEmpty(studios)) {
    return studios.map(
      (studio: StudiosApi.Studio): StudiosTableRow => ({
        id: studio.id,
        name: studio.name,
        country: studio.country,
        city: studio.city,
        address: studio.address,
        description: studio.description,
        schedule: studio.schedule,
        mainPhoto: studio.mainPhoto,
        offset: studio.offset,
        organization: studio.organization?.name,
        rooms: studio.rooms?.length,
        directions: studio.directions,
        directionsCount: studio.directionsCount,
        photos: studio.photos,
      })
    )
  }
}

export function mapStudiosCitiesToOptions(cities: Nullable<string[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(cities)) {
    return cities.map(
      (city: string): DefaultOptionType => ({
        value: city,
        label: city,
      })
    )
  }
}

export function mapStudiosCountriesToOptions(countries: Nullable<string[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(countries)) {
    return countries.map(
      (country: string): DefaultOptionType => ({
        value: country,
        label: country,
      })
    )
  }
}

export function genStudiosDTO(values: StudiosFormTypes): StudiosApi.StudioCreateDTO {
  const { photos } = values
  return {
    name: values.name,
    country: values.country,
    city: values.city,
    address: values.address,
    orgId: values.orgId,
    workTime: {
      dailyWorkTime: values.workTime,
    },

    description: values.description,
    locationDescription: values.locationDescription,
    locationPhotoUrl: values.locationPhotoUrl,
    locationMapUrl: values.locationMapUrl,

    amenities: values.amenities,

    rooms: values.rooms.map(room => {
      return {
        name: room.name,
        totalCapacity: room.totalCapacity,
        description: room.description,
        locationDescription: room.locationDescription,
        locationMapUrl: room.locationMapUrl,
        workTime: room.workTime && {
          dailyWorkTime: room.workTime,
        },
        directionsIds: isDefAndNotEmpty(room.directionsIds)
          ? (room.directionsIds.map(direction => direction.value) as string[])
          : [],
        locationPhotoUrl: room.locationPhotoUrl,
        amenities: isDefAndNotEmpty(room.amenities) ? room.amenities.filter(amenity => amenity?.name) : [],
        photos: isDefAndNotEmpty(photos) ? photos.map(file => file.response) : [],
        mainPhoto: isDefAndNotEmpty(photos) ? photos[0].response : null,
      }
    }),
    offset: values.offset,
    photos: isDefAndNotEmpty(photos) ? photos.map(file => file.response as string) : [],
    mainPhoto: isDefAndNotEmpty(photos) ? photos[0].response : null,
    showToUser: !values.hideStudio,
  }
}

export function genStudiosEditDTO(values: StudiosFormTypes): StudiosApi.StudioUpdateDTO {
  const { photos } = values
  return {
    name: values.name,
    country: values.country,
    city: values.city,
    address: values.address,
    orgId: values.orgId,
    workTime: {
      dailyWorkTime: values.workTime,
    },
    description: values.description,
    locationDescription: values.locationDescription,
    locationPhotoUrl: values.locationPhotoUrl,
    locationMapUrl: values.locationMapUrl,
    amenities: values.amenities,
    mainPhoto: isDefAndNotEmpty(photos) ? photos[0].response : null,
    photos: isDefAndNotEmpty(photos) ? photos.map(file => file.response as string) : [],
    offset: values.offset,
    showToUser: !values.hideStudio,
  }
}

function mapStudioWorkTimeToUnifiedTime(values: Nullable<WorkTime>): { start: string; end: string } {
  if (isDef(values)) {
    const workTimeValuesArray: Range<string>[] = Object.values(values)
    if (isDefAndNotEmpty(workTimeValuesArray) && isContainArrayDaysWithSameTime(workTimeValuesArray)) {
      return {
        start: dayjs(workTimeValuesArray[0].from, 'HH:mm').format('HH:mm'),
        end: dayjs(workTimeValuesArray[0].to, 'HH:mm').format('HH:mm'),
      }
    }
  }
  return { start: '00:00', end: '00:00' }
}

export function genStudiosFormValues(values: Nullable<StudiosApi.Studio>): Nullable<StudiosFormTypes> {
  if (isDef(values)) {
    return {
      ...values,
      orgId: values.organization.id,
      photos: values?.photos?.map((photo, index) => ({
        uid: String(index),
        name: `Photo${index}`,
        status: 'done',
        response: photo,
        url: photo,
      })) as UploadFile[],
      unifiedTime: mapStudioWorkTimeToUnifiedTime(values.workTime.dailyWorkTime),
      workTime: values.workTime.dailyWorkTime,
      amenities: isDefAndNotEmpty(values.amenities) ? values.amenities : [],
      hideStudio: !values.showToUser,
      rooms: isDefAndNotEmpty(values.rooms)
        ? values.rooms.map(room => {
            const { id, name, totalCapacity, description, locationDescription, locationPhotoUrl, locationMapUrl } = room
            return {
              id,
              name,
              totalCapacity,
              description,
              locationDescription,
              locationPhotoUrl,
              locationMapUrl,
              color: room.color.toUpperCase(),
              photos: room?.photos?.map((photo, index) => ({
                uid: String(index),
                name: `Photo${index}`,
                status: 'done',
                response: photo,
                url: photo,
              })) as UploadFile[],
              amenities: isDefAndNotEmpty(room.amenities) ? room.amenities : [],
              customFields: isDefAndNotEmpty(room.customFields) ? room.customFields : [],
              directionsIds: isDefAndNotEmpty(room.directions)
                ? room.directions.map(direction => ({ label: direction.name, value: direction.id }))
                : [],
              workTime: room.workTime && room.workTime.dailyWorkTime,
              unifiedTime: mapStudioWorkTimeToUnifiedTime(room.workTime?.dailyWorkTime),
            }
          })
        : [],
    }
  }
}

export function getStudioWorkTimes(studio: Nullable<StudiosApi.Studio>): Nullable<StudiosApi.StudioWorkTime> {
  if (isDef(studio) && isDef(studio.workTime)) {
    return studio.workTime
  }
}

export function getStudioRoomsTimes(
  studiosRooms: Nullable<StudiosRoomsApi.StudioRoom[]>
): Nullable<StudiosApi.StudioWorkTime[]> {
  return studiosRooms?.map(studio => studio?.workTime ?? {})
}

export function getStudioWorkTimesOnSelectedDate(
  date: NString,
  studioWorkTime: Nullable<StudiosApi.StudioWorkTime>
): TimetableEventWorkTime {
  if (!date) return

  const dayOfWeek = dayjs(date).weekday()
  const dayName = Object.values(Days)[dayOfWeek]
  const day = validateStrEnumValue<Days>(Days, dayName)
  if (
    isDef(day) &&
    isDef(studioWorkTime) &&
    isDef(studioWorkTime.dailyWorkTime) &&
    isDef(studioWorkTime.dailyWorkTime[day])
  ) {
    return {
      from: dayjs(studioWorkTime.dailyWorkTime[day]?.from, 'HH:mm').format('HH:mm'),
      to: dayjs(studioWorkTime.dailyWorkTime[day]?.to, 'HH:mm').format('HH:mm'),
    }
  } else {
    return {
      from: null,
      to: null,
    }
  }
}

export const findMaxWorkTime = (workTimeArray: Nullable<StudiosApi.StudioWorkTime[]>) => {
  let maxDuration = 0
  let maxObj = null

  if (!workTimeArray?.length) return null

  workTimeArray.forEach(obj => {
    if (obj.dailyWorkTime) {
      let objDuration = 0
      for (let day in obj.dailyWorkTime) {
        const from = dayjs(obj.dailyWorkTime[day].from, 'HH:mm')
        const to = dayjs(obj.dailyWorkTime[day].to, 'HH:mm')
        objDuration += to.diff(from)
      }
      if (objDuration > maxDuration) {
        maxDuration = objDuration
        maxObj = obj
      }
    }
  })

  return maxObj
}
