export function genCreateStudiosSteps(): string[] {
  return ['Основная информация', 'Залы и направления']
}

export function genEditStudiosSteps(): string[] {
  return ['Основная информация', 'Залы и направления', 'Подключение офлайн кассы']
}

export function genStudioInitialValues() {
  return {
    name: '',
    country: '',
    city: '',
    address: '',
    description: '',
    mainPhoto: '',
    photos: [],
    orgId: null,
    workTime: {},
    rooms: [{ workTime: {}, amenities: [{}] }],
    amenities: [{}],
    offset: 3,
    hideStudio: false,
  }
}
