import { FC, useEffect, useMemo, useState } from 'react'
import { Col, Form, Row, Select, Button, Radio, Tag, Flex, Dropdown, DropdownProps } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { PlusCircleOutlined, FilterOutlined } from '@ant-design/icons'
import { DefaultOptionType } from 'antd/lib/select'
import { LOCAL_STORAGE_ROOMS_KEY, LOCAL_STORAGE_TAGS_KEY } from '@constants/local-storage'

import { ExercisesFiltersProps, ExercisesFiltersTag } from './exercises-filters.types'
import { getTheme } from '../../../store/common/layout/layout.selectors'
import { useSchedulePageTable } from '../../../pages/schedule-page/schedule-page-table/schedule-page-table.hook'
import { schedulePageListActions } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.slice'
import { genSchedulePageListTimetable } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import './exercises-filters.styles.less'

export const ExercisesFilters: FC<ExercisesFiltersProps> = ({
  form,
  roomOptions,
  trainerOptions,
  directionOptions,
  recordOptions,
  paymentTypesOptions,
}) => {
  // Store
  const theme = useSelector(getTheme)
  const dispatch = useDispatch()

  // Custom hooks
  const { onAddHandler } = useSchedulePageTable()

  // Update timetable
  const currentTimetable = useSelector(genSchedulePageListTimetable)
  const onSelectFilters = (tags: ExercisesFiltersTag[]) =>
    dispatch(schedulePageListActions.filterByTags({ tags, timetableType: currentTimetable }))

  // Filters
  const [rooms, setRooms] = useState<string[]>([])
  const [tags, setTags] = useState<ExercisesFiltersTag[]>([])
  const [hasDefaultValues, setDefaultValues] = useState(false)

  useEffect(() => {
    const tags = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TAGS_KEY) || '[]')
    const rooms: string[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ROOMS_KEY) || '[]')

    const roomsOptionsIds = (roomOptions ?? []).map(room => room.value)
    const filteredRooms = rooms.filter(roomId => roomsOptionsIds.includes(roomId))

    if (filteredRooms.length !== rooms.length) {
      localStorage.setItem(LOCAL_STORAGE_ROOMS_KEY, JSON.stringify(filteredRooms))
      window.dispatchEvent(new StorageEvent(LOCAL_STORAGE_ROOMS_KEY))
    }

    setTags(tags)
    setRooms(filteredRooms)
    setDefaultValues(true)
  }, [])

  useEffect(() => {
    const roomsTags = rooms.map(room => ({ label: String(room), value: String(room), type: 'rooms' }))
    onSelectFilters([...tags, ...roomsTags])

    localStorage.setItem(LOCAL_STORAGE_TAGS_KEY, JSON.stringify(tags))
    localStorage.setItem(LOCAL_STORAGE_ROOMS_KEY, JSON.stringify(rooms))
    window.dispatchEvent(new StorageEvent(LOCAL_STORAGE_ROOMS_KEY))
  }, [tags, rooms, currentTimetable])

  // Set one room for weekly by default
  useEffect(() => {
    if (!hasDefaultValues) return

    if (currentTimetable === 'weekly' && roomOptions?.length && !rooms.length) {
      setRooms(roomOptions.map(room => String(room.value)))
    }
  }, [roomOptions, currentTimetable, rooms, hasDefaultValues])

  const selectOptions = useMemo(() => {
    if (!roomOptions) return []
    if (rooms.length === 1 && currentTimetable === 'weekly') {
      return roomOptions.map(option => (option.value === rooms[0] ? { ...option, disabled: true } : option))
    } else {
      return roomOptions
    }
  }, [rooms, roomOptions, currentTimetable])

  // Actions
  const toggleCurrentTimetable = () => {
    setRooms([])
    dispatch(schedulePageListActions.updateCurrentTimetable())
  }

  const handleChangeRooms = (value: string[]) => {
    setRooms(value)
  }

  const clearFilters = () => {
    setRooms([])
    setTags([])
  }

  // Dropdown
  const [open, setOpen] = useState(false)
  const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
    if (info.source === 'trigger') {
      setOpen(nextOpen)
    }
  }

  const optionsToItems = (options: DefaultOptionType[] | undefined, type: string) => {
    if (!options) return

    return options.map(option => {
      return {
        key: option.value,
        label: (
          <Button
            type="text"
            className="exercises-filters__item"
            onClick={() => {
              if (tags.some(item => item.value === String(option.value))) {
                setTags(tags.filter(item => item.value !== String(option.value)))
                return
              }
              setTags([...tags, { label: String(option.label), value: String(option.value), type }])
            }}
          >
            <span>{option.label}</span>
            {tags.some(item => item.value === String(option.value)) && <CheckOutlined />}
          </Button>
        ),
      }
    })
  }

  const items = [
    {
      key: '1',
      label: 'Категория записи',
      children: optionsToItems(recordOptions, 'records'),
    },
    {
      key: '2',
      label: 'Исполнитель',
      children: optionsToItems(trainerOptions, 'trainers'),
    },
    {
      key: '3',
      label: 'Направления',
      children: optionsToItems(directionOptions, 'directions'),
    },
    {
      key: '4',
      label: 'Статус оплаты',
      children: optionsToItems(paymentTypesOptions, 'paymentTypes'),
    },
  ]

  return (
    <>
      <Form form={form} autoComplete="off" className={`exercises-filters exercises-filters--${theme}`}>
        <Flex vertical gap="8px">
          <Row className="schedule-page-filter" align="top" gutter={[10, 10]}>
            <Col>
              <Form.Item name="roomId" style={{ margin: 0 }}>
                <p style={{ display: 'none' }}>{rooms.join(',')}</p>
                <Select
                  mode="multiple"
                  style={{ width: 260 }}
                  placeholder="Выбор зала"
                  options={selectOptions}
                  onChange={handleChangeRooms}
                  value={rooms}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="roomId" style={{ margin: 0 }}>
                <Dropdown
                  onOpenChange={handleOpenChange}
                  open={open}
                  className="schedule-page-filters__dropdown"
                  menu={{ items }}
                >
                  <Button icon={<FilterOutlined />}>Все фильтры</Button>
                </Dropdown>
              </Form.Item>
            </Col>

            <Col className="schedule-page__button-container" flex="auto">
              <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => onAddHandler()}>
                Создать запись
              </Button>
            </Col>
          </Row>
        </Flex>
      </Form>
      <Flex gap="middle" justify="space-between" className="schedule-page-filters">
        <Row align="top" className="schedule-page-filter">
          <Col className="schedule-page-filter__tags">
            {tags.map(tag => (
              <Tag key={tag.value} closable onClose={() => setTags(tags.filter(item => item.value !== tag.value))}>
                {tag.label}
              </Tag>
            ))}
          </Col>

          <Col>
            {tags.length !== 0 && (
              <Button size="small" type="text" onClick={() => clearFilters()}>
                Очистить
              </Button>
            )}
          </Col>
        </Row>

        <Row className="schedule-page-filter" align="top" justify="end">
          <Col>
            <Radio.Group size="small" onChange={() => toggleCurrentTimetable()} value={currentTimetable}>
              <Radio.Button value="weekly">Неделя</Radio.Button>
              <Radio.Button value="daily">День</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </Flex>
    </>
  )
}
