import { Line } from 'react-chartjs-2'
import { Button, Select } from 'antd'
import axios from 'axios'
import { AimOutlined, CaretDownFilled, CaretUpFilled, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  LogarithmicScale,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { FunnelController, TrapezoidElement } from 'chartjs-chart-funnel'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import clsx from 'clsx'

import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { formatRubleCurrency } from '../../format/number.format'
import styles from './line-chart.module.scss'

export const LineChart = React.memo(() => {
  const ref = useRef<ChartJSOrUndefined<'line', number[], string>>()
  const [gradient, setGradient] = useState<CanvasGradient>()
  const [gradient2, setGradient2] = useState<CanvasGradient>()

  const [dateSum, setDateSum] = useState<{ number: number }[]>([])
  const [refunds, setRefunds] = useState<{ number: number }[]>([])
  const [summary, setSummary] = useState(0)
  const [refundSum, setRefundSum] = useState(0)
  const [presult, setPresult] = useState(0)
  const [yresult, setYresult] = useState(0)
  const [average, setAverage] = useState(0)

  const [isLoading, setIsLoading] = useState(false)

  useLayoutEffect(() => {
    ChartJS.register(
      FunnelController,
      TrapezoidElement,
      LinearScale,
      CategoryScale,
      PointElement,
      LogarithmicScale,
      LineElement,
      Filler,
      Title,
      Tooltip,
      Legend
    )
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    const { data } = await axios({
      method: 'post',
      url: `https://demo.ai.vivacrm.ru/rpm`,
      withCredentials: false,
    })

    setDateSum(data.datesum)
    setRefunds(data.refund)
    setSummary(data.summary)
    setRefundSum(data.rsum)
    setPresult(data.presult)
    setYresult(data.yresult)
    setAverage(data.average)

    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (ref.current && !gradient && !gradient2) {
      // @ts-ignore
      const gradientStroke = ref.current.ctx.createLinearGradient(0, 0, 0, 280)
      gradientStroke.addColorStop(0, 'rgb(22, 119, 255)')
      gradientStroke.addColorStop(0.5, 'rgb(22, 119, 255)')
      gradientStroke.addColorStop(0.1, '#fff')

      // @ts-ignore
      const gradientStroke2 = ref.current.ctx.createLinearGradient(0, 0, 0, 280)

      gradientStroke2.addColorStop(0, 'rgb(255, 22, 22)')
      gradientStroke2.addColorStop(0.5, 'rgb(255, 22, 22)')
      gradientStroke2.addColorStop(0.1, '#fff')
      gradientStroke2.addColorStop(1, '#fff')

      setGradient(gradientStroke)
      setGradient2(gradientStroke2)
    }
  }, [ref.current, gradient, gradient2])

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div>
          <PageLoader />
        </div>
      ) : (
        <>
          <div className={styles.top}>
            <div>
              <div className={styles.topDesc}>Обороты</div>
              <div className={styles.topPrice}>{formatRubleCurrency(summary, true)}</div>
              <div className={styles.topHint}></div>
            </div>
            <div>
              <div className={styles.topDesc}>
                Возвраты <InfoCircleOutlined />
              </div>
              <div className={clsx(styles.topPrice, styles.refundsNum)}>{formatRubleCurrency(refundSum, true)}</div>
            </div>
          </div>
          <div>
            <Select
              className={styles.select}
              value="1"
              options={[
                {
                  value: '1',
                  label: 'Все разовые услуги, персональные услуги, товары',
                },
              ]}
            />
          </div>
          <div className={styles.stats}>
            <div>
              <div className={styles.statItem}>
                За период {presult >= 0 ? `+${presult}%` : `${presult}%`}{' '}
                {presult >= 0 ? (
                  <CaretUpFilled color="rgba(82, 196, 26, 1)" className={styles.up} />
                ) : (
                  <CaretDownFilled color="rgba(255, 77, 79, 1)" className={styles.down} />
                )}
              </div>
              <div className={styles.statItem}>
                Вчера {yresult >= 0 ? `+${yresult}%` : `${yresult}%`}{' '}
                {yresult >= 0 ? (
                  <CaretUpFilled color="rgba(82, 196, 26, 1)" className={styles.up} />
                ) : (
                  <CaretDownFilled color="rgba(255, 77, 79, 1)" className={styles.down} />
                )}
              </div>
            </div>
            <div>
              <div className={styles.statItem}>
                ср. чек {formatRubleCurrency(average, true)}{' '}
                {average >= 0 ? (
                  <CaretUpFilled color="rgba(82, 196, 26, 1)" className={styles.up} />
                ) : (
                  <CaretDownFilled color="rgba(255, 77, 79, 1)" className={styles.down} />
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              maxWidth: '100%',
              // @ts-ignore
              overflowY: 'overlay',
              height: 100,
            }}
          >
            {/* @ts-ignore */}
            <Line
              data={{
                labels: dateSum.map(() => ''),
                datasets: [
                  {
                    label: 'Количество продаж',
                    data: dateSum.map(el => el.number || 0.0001),
                    backgroundColor: gradient,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: 'transparent',
                    borderColor: 'rgb(22, 119, 255)',
                    borderWidth: 1,
                    // backgroundColor: 'rgba(255, 77, 79, 0.5)',
                    fill: true,
                    yAxisID: 'y',
                  },
                  {
                    label: 'Возврат',
                    data: refunds.map(el => el.number || 0.00001),
                    backgroundColor: gradient2,
                    borderColor: 'rgb(255, 22, 22)',
                    borderWidth: 1,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: 'transparent',
                    // backgroundColor: 'rgba(22, 119, 255, 1)',
                    fill: true,
                    yAxisID: 'y1',
                  },
                ],
              }}
              ref={ref}
              options={{
                aspectRatio: 8,
                responsive: true,
                interaction: {
                  mode: 'index' as const,
                  intersect: false,
                },
                scales: {
                  xAxes: {
                    display: false,
                    ticks: {
                      padding: 10,
                      stepSize: 10,
                    },
                  },
                  yAxes: {
                    display: false,
                    stacked: true,
                    stackWeight: 100,
                    grid: {
                      tickLength: 100,
                    },
                    ticks: {
                      maxTicksLimit: 100,
                      stepSize: 10,
                      count: 1000,
                    },
                  },
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    type: 'logarithmic',
                    display: false,
                    position: 'left' as const,
                  },
                  y1: {
                    type: 'logarithmic' as const,
                    display: false,
                    position: 'right' as const,
                    grid: {
                      drawOnChartArea: true,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
          <div className={styles.footer}>
            <Button icon={<DownloadOutlined />}>Excel</Button>
            <Button icon={<AimOutlined />}>Сводка от ИИ</Button>
          </div>
        </>
      )}
    </div>
  )
})
